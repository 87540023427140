import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import { Routes, Route } from "react-router-dom";
import Jobs from "./Pages/Jobs/Jobs";
import Landing from "./Pages/Landing/Landing";
import RecentBlogs from "./components/Blogs-pages/RecentBlogs";
import BlogDetails from "./components/Blogs-pages/BlogDetails";
import VCard from "./Pages/vCard/vCard";
import ContactUs from "./components/ContactUs";
import UIContextProvider from "./contexts/UIContext";
import SecondBlog from './components/Blogs-pages/SecondBlog'
import ThirdBlog from  './components/Blogs-pages/ThirdBlog'
import FourthBlog from './components/Blogs-pages/FourthBlog'
import FifthBlog from './components/Blogs-pages/FifthBlog'
function App() {
  return (
    <UIContextProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/blogs" element={<RecentBlogs />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/blog-details-two" element={<SecondBlog />} />
                    <Route path="/blog-details-three" element={<ThirdBlog />} />
                    <Route path="/blog-details-four" element={<FourthBlog />} />
                    <Route path="/blog-details-five" element={<FifthBlog />} />


          <Route path="/vCard" element={<VCard />} />
          {/* <Route path="/contactUs" element={<ContactUs />} /> */}
        </Routes>
      </div>
    </UIContextProvider>
  );
}

export default App;
